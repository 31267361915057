import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import portfolioEmileTerHuerne from "../images/portfolio-emile-ter-huerne.png"
import portfolioSupermanager from "../images/portfolio-supermanager.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExternalLinkAlt,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const PortfolioItem = ({ name, domain, url, backgroundImage }) => (
  <>
    <main
      className="relative group bg-gray-300 bg-cover bg-no-repeat rounded-sm w-full h-56"
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    >
      <a
        href={url}
        className="bg-black-80% invisible group-hover:visible absolute flex items-center flex-col justify-center top-0 bottom-0 left-0 right-0 text-white rounded-sm"
      >
        <span className="font-bold">Bezoek website</span>
        <span className="text-gray-500">
          {domain}
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            className="hidden sm:inline ml-1"
          />
        </span>
      </a>
    </main>
    <footer className="px-2">
      <h3 className="h3 mt-2 md:mt-4 mb-0">{name}</h3>
      <a href={url} className="link text-gray-500 truncate inline-block w-full">
        {domain}
      </a>
    </footer>
  </>
)

export default function Portfolio() {
  return (
    <Layout>
      <Seo title="Portfolio" />
      <div className="container py-8">
        <h1 className="h1">Portfolio van Thomas' Webdesign</h1>
        <p className="paragraph">
          Bekijk hier eerder opgeleverde websites van onze klanten.
        </p>

        <div className="row mt-2">
          <div className="column w-1/2">
            <PortfolioItem
              name="Hoveniersbedrijf Emile ter Huerne"
              domain="emileterhuerne.nl"
              url="https://emileterhuerne.nl/"
              backgroundImage={portfolioEmileTerHuerne}
            />
            <p className="paragraph mt-2">
              Emile vindt het belangrijk om online vindbaar te zijn. Zijn
              website dient als een{" "}
              <em className="highlight">online visitekaartje</em>. Een plek waar
              men nog eens rustig kan doorlezen welke diensten zijn
              hoveniersbedrijf aanbiedt.
            </p>

            <p className="paragraph mt-2">
              Bij zijn vorige provider was de service ondermaats en was het
              lastig om wijzigingen te laten maken. Telkens werd gevraagd om
              extra te betalen.
            </p>
            <p className="paragraph mt-2">
              Thomas' Webdesign heeft Emile geholpen met een compleet nieuwe
              website, die snel laadt, en goed te vinden is via Google. Het{" "}
              persoonlijk contact met iemand met verstand van zaken is wat Emile
              met name op prijs stelde.
            </p>

            <a
              href="https://emileterhuerne.nl/"
              className="button button--ghost mt-4"
            >
              Bekijk website
            </a>
          </div>
          <div className="column w-1/2">
            <PortfolioItem
              name="Ajax-B Supermanager"
              domain="ajaxb-supermanager.nl"
              url="http://ajaxb-supermanager.nl/"
              backgroundImage={portfolioSupermanager}
            />

            <p className="paragraph mt-2">
              Ajax-B Supermanager is een voetbalspel waarbij je een team samen
              stelt van spelers uit de eredivise. Je kunt punten verdienen door
              de prestaties van je spelers te voorspellen.
            </p>

            <p className="paragraph mt-2">
              Thomas' Webdesign heeft de website van Ajax-B Supermanager{" "}
              <em className="highlight">op maat ontwikkeld</em>. Via de website
              kunnen spelers zich inschrijven, en kunnen ze hun team
              samenstellen.
            </p>

            <p className="paragraph mt-2">
              Elke week worden de punten van de spelers bijgewerkt, en worden de
              stand en de ranglijst bijgewerkt.
            </p>

            <a
              href="http://ajaxb-supermanager.nl/"
              className="button button--ghost mt-4"
            >
              Bekijk website
            </a>
          </div>
        </div>

        <footer className="bg-gray-300 p-8 mt-4 md:mt-8 rounded-sm">
          <div className="flex flex-wrap items-center -m-2">
            <main className="flex-grow p-2">
              <h2 className="h2 mb-0">
                Benieuwd hoe uw website er uit kan zien?
              </h2>
              <p className="text">
                Professionele webdesign en webhosting tegen een eerlijke prijs,
                afhankelijk van uw wensen.
              </p>
            </main>
            <aside className="p-2">
              <Link to="/portfolio" className="button">
                Bekijk tarieven
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </Link>
            </aside>
          </div>
        </footer>
      </div>
    </Layout>
  )
}
